import React from 'react';
import './InvitationPanel.scss';
import { replaceText } from '../../helper/helper';
import ModalOverlay from '../Globals/ModalOverlay';
import AudioPanel from './AudioPanel';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { dispatchCloseInvitationPanel, dispatchOpenInvitationPanel } from '../../redux/actions/application';
import { Invitation } from './Invitation';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const InvitationPanel = () => {
    const texts = useSelector((state: RootState) => state.texts.texts);
    const invitationPanelIsOpen = useSelector((state: RootState) => state.application.invitationPanelIsOpen);
    const audioStreamIsActive = useSelector((state: RootState) => state.application.audioStreamIsActive);
    const isConnected = useSelector((state: RootState) => state.connection.isConnected);
    const audioStreamFeature = useSelector((state: RootState) => state.features.audioStreamFeature);
    const inviteFeature = useSelector((state: RootState) => state.features.inviteFeature);
    const disclaimerFeatureCaller = useSelector((state: RootState) => state.features.disclaimerFeatureCaller);
    const usageDisclaimerStateCaller = useSelector((state: RootState) => state.disclaimers.usageDisclaimerStateCaller);

    const promptToActivateAudioAndProceed = () => {
        if (!audioStreamIsActive && audioStreamFeature) {
            addNotificationAndShowDispatch('warning.invite.audioDependency', 'warning', true);
        } else {
            dispatchOpenInvitationPanel();
        }
    };

    const disableConditions = () => {
        if (disclaimerFeatureCaller) {
            if (usageDisclaimerStateCaller !== 'accepted' || !isConnected) {
                return true;
            } else {
                return false;
            }
        } else {
            if (!isConnected) {
                return true;
            } else {
                return false;
            }
        }
    };

    return (
        <div className="invitationPanel">
            <AudioPanel />
            {inviteFeature && (
                <>
                    <button className="btn btn--primary" onClick={promptToActivateAudioAndProceed} disabled={disableConditions()}>
                        {replaceText(texts, 'invitation.button')}
                    </button>

                    <ModalOverlay modalIsVisible={invitationPanelIsOpen} hasCloseButton closeHandler={dispatchCloseInvitationPanel}>
                        <Invitation />
                    </ModalOverlay>
                </>
            )}
        </div>
    );
};
